
<script>

import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Owner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
 
    Switches,
    Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Add Restaurant Owner",
      items: [
        {
          text: "Restaurant Owner",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


      status :'',
      submitted: false,
      restaurants:[],
      showAlert :false,
      loading: false,
   
 
      posts:{

        restaurantID:null,
        name :null,
        emailID:'',
        phoneNO:'',
       
      }
    };
  },

    validations: {
    posts: {
     restaurantID:{
         required,
     },
      name:{
        required,
      },
       emailID:{
        required,
      },
      phoneNO:{
        required,
      },
    },
  },
  methods:{

    submitRestOwnerForm(e){

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          let formData = new FormData();  
           /*
                Add the form data we need to submit
            */
            
            formData.append('emailID', this.posts.emailID);
            formData.append('phoneNO', this.posts.phoneNO);
            formData.append('name',this.posts.name);
            formData.append('restaurantID',this.posts.restaurantID.restID);
            

      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addRestaurantOwner",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
        this.$router.go();
          console.warn(result);
      })
     .catch(function (error) {
                        console.error(error.response);
                        alert("Something went Wrong");
                    });
      }  
    },

    getRestaurantList(){
         this.axios.get(this.$loggedRole+"/getRestaurantList")
          .then((result)=>{
            this.restaurants = result.data.data;
              
        });
    },
    


  },
   
  mounted(){

    this.getRestaurantList();

  },
  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
<form @submit.prevent="submitRestOwnerForm" method="post">
                <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" v-show="display1">
                                                A simple success alert—check it out!
                                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                                                </button>
                                            </div> -->
    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">

                <div class="col-md-12 mb-3">
                      <label>Choose Restaurant </label>
                     <multiselect v-model="posts.restaurantID" :options="restaurants" track-by="restID" label="name" @input="getRestByID($event);"
                     :class="{
                        'is-invalid': submitted && $v.posts.restaurantID.$error,
                      }" ></multiselect>
                       <div
                      v-if="submitted && $v.posts.restaurantID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.restaurantID.required"
                        >This value is required.</span
                      >
                    </div>

                </div>

                   <div class="mb-3">
                    <label class="form-label" for="formrow-name-input">Name</label>
                    <input type="text" class="form-control" id="formrow-name-input" v-model="posts.name"
                    :class="{
                        'is-invalid': submitted && $v.posts.name.$error,
                      }"
                    >
                     <div
                      v-if="submitted && $v.posts.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.name.required"
                        >This value is required.</span
                      >
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label" for="formrow-emailID-input">Email ID</label>
                    <input type="text" class="form-control" id="formrow-emailID-input" v-model="posts.emailID"
                    :class="{
                        'is-invalid': submitted && $v.posts.emailID.$error,
                      }"
                    >
                     <div
                      v-if="submitted && $v.posts.emailID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.emailID.required"
                        >This value is required.</span
                      >
                    </div>
                </div>
                <div class="mb-4">
                    <label class="form-label" for="input-mask">Phone No.</label>
                    <input id="input-mask" class="form-control input-mask" data-inputmask="'mask': '99-99999999'"
                     im-insert="true" v-model="posts.phoneNO"
                     :class="{
                        'is-invalid': submitted && $v.posts.phoneNO.$error,
                      }">
                    <span class="text-muted">e.g "99-9999999"</span>
                     <div
                      v-if="submitted && $v.posts.phoneNO.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.posts.phoneNO.required"
                        >This value is required.</span
                      >
                    </div>
                </div>
      
              
            </div>
            </div>
        </div>


    </div>



     <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                    class="m-2 col-3"
                    variant="primary"
                    role="status"
                  ></b-spinner>
                  <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
      </div>
</form>
<!-- Success circle button with ripple effect -->

  

</Layout>
</template>

<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}

</style>


